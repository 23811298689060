
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import util from '../lib/util'
import AbpBase from '../lib/abpbase'
import DashboardLayout from '../layouts/Dashboard.vue'
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

@Component({
  components: {
    DashboardLayout
  }
})
export default class Main extends AbpBase {
  shrink: boolean = false
  $store: any
  get userName() {
    return this.$store.state.session.user ? this.$store.state.session.user.name : ''
  }
  isFullScreen: boolean = false
  messageCount: string = '0'
  get openedSubmenuArr() {
    return this.$store.state.app.openedSubmenuArr
  }
  get menuList() {
    return this.$store.state.app.menuList
  }
  get pageTagsList() {
    return this.$store.state.app.pageOpenedList as Array<any>
  }
  get currentPath() {
    return this.$store.state.app.currentPath
  }
  get lang() {
    return this.$store.state.app.lang
  }
  get avatorPath() {
    return localStorage.avatorImgPath
  }
  get cachePage() {
    return this.$store.state.app.cachePage
  }
  get menuTheme() {
    return this.$store.state.app.menuTheme
  }
  get mesCount() {
    return this.$store.state.app.messageCount
  }
  init() {
    let pathArr = util.setCurrentPath(this, this.$route.name as string)
    this.$store.commit('app/updateMenulist')
    if (pathArr.length >= 2) {
      this.$store.commit('app/addOpenSubmenu', pathArr[1].name)
    }

    let messageCount = 3
    this.messageCount = messageCount.toString()
    this.checkTag(this.$route.name)
  }
  toggleClick() {
    this.shrink = !this.shrink
  }
  handleClickUserDropdown(name: string) {
    if (name === 'ownSpace') {
      util.openNewPage(this, 'ownspace_index', null, null)
      this.$router.push({
        name: 'ownspace_index'
      })
    } else if (name === 'loginout') {
      this.$store.commit('app/logout', this)
      util.abp.auth.clearToken()
      location.reload()
    }
  }

  checkTag(name?: string) {
    let openpageHasTag = this.pageTagsList.some((item: any) => {
      if (item.name === name) {
        return true
      } else {
        return false
      }
    })
    if (!openpageHasTag) {
      util.openNewPage(this, name as string, this.$route.params || {}, this.$route.query || {})
    }
  }
  handleSubmenuChange(val: string) {
    // console.log(val)
  }
  beforePush(name: string) {
    if (name === 'accesstest_index') {
      return false
    } else {
      return true
    }
  }
  fullscreenChange(isFullScreen: boolean) {
    // console.log(isFullScreen);
  }
  @Watch('$route')
  routeChange(to: any) {
    this.$store.commit('app/setCurrentPageName', to.name)
    let pathArr = util.setCurrentPath(this, to.name)
    if (pathArr.length > 2) {
      this.$store.commit('app/addOpenSubmenu', pathArr[1].name)
    }
    this.checkTag(to.name)
    localStorage.currentPageName = to.name
  }
  @Watch('lang')
  langChange() {
    util.setCurrentPath(this, this.$route.name as string)
  }
  hasElement(className) {
    return document.getElementsByClassName(className).length > 0
  }

  initScrollbar(className) {
    if (this.hasElement(className)) {
      new PerfectScrollbar(`.${className}`)
    } else {
      setTimeout(() => {
        this.initScrollbar(className)
      }, 100)
    }
  }
  initscrollbar() {
    let isWindows = navigator.platform.startsWith('Win')
    if (isWindows) {
      this.initScrollbar('sidenav')
    }
  }
  mounted() {
    this.init()
    this.initscrollbar();
  }
  created() {
    this.$store.commit('app/setOpenedList')
    abp.event.on('abp.notifications.received', (userNotification) => {
      this.$store.dispatch({
        type: 'app/getNoticeList',
        data: this.$store.state.session.user.id || 0
      })
    })
  }
}
