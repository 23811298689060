<template>
  <div>
    <b-overlay :show="loading" no-wrap fixed z-index="1100"></b-overlay>
    <!-- <slide-x-left-transition :duration="animationDuration"> -->
            <!-- class="modal fade" -->
          
    <b-modal
      :id="id"
      class="modal"
      ref="app-modal"
      :size="size"
      :hide-header="!$slots.header"
      :modal-class=" modalClasses != null ? [{ 'modal-mini': type === 'mini' }, ...modalClasses] : []"            
      @mousedown.self="closeModal"
      tabindex="-1"
      role="dialog"
      @close="closeModal"
      @hide="hide"
      @shown="shown"
      :header-class="headerClasses"
      :footer-class="footerClasses"
      :content-class="modalContentClasses != null && gradient != null ? [gradient ? `bg-gradient-${gradient}` : '', ...modalContentClasses] : []"        
      :body-class="bodyClasses"
      :aria-hidden="!show"
      @ok="ok"
      :no-fade="'no-fade'"
      :no-enforce-focus="'no-enforce-focus'"
      @change="change"
      :ok-only="'ok-only'"
      z-index="1000"
    >
      <template v-slot:modal-header>
        <slot name="header"></slot>
        <slot name="close-button">
          <button type="button" class="close" v-if="showClose" @click="closeModal" data-dismiss="modal" aria-label="Close">×</button>
        </slot>
      </template>
            <slot />
      <template v-slot:modal-footer>
        <slot name="footer"></slot>
      </template>
    </b-modal>
  </div>
  <!-- </slide-x-left-transition> -->
</template>
<script>
import { SlideXLeftTransition } from 'vue2-transitions'

export default {
  name: 'modal',
  components: {
    SlideXLeftTransition
  },
  props: {
    id: String,
    show: Boolean,
    showClose: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: '',
      validator(value) {
        let acceptedValues = ['', 'notice', 'mini']
        return acceptedValues.indexOf(value) !== -1
      },
      description: 'Modal type (notice|mini|"") '
    },
    modalClasses: {
      type: [Object, String],
      description: 'Modal dialog css classes'
    },
    size: {
      type: String,
      description: 'Modal size',
      validator(value) {
        let acceptedValues = ['xl', 'sm', 'lg', 'm']
        return acceptedValues.indexOf(value) !== -1
      }
    },
    modalContentClasses: {
      type: [Object, String],
      description: 'Modal dialog content css classes'
    },
    gradient: {
      type: String,
      description: 'Modal gradient type (danger, primary etc)'
    },
    headerClasses: {
      type: [Object, String],
      description: 'Modal Header css classes'
    },
    bodyClasses: {
      type: [Object, String],
      description: 'Modal Body css classes'
    },
    footerClasses: {
      type: [Object, String],
      description: 'Modal Footer css classes'
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: 'Modal transition duration'
    },
    'no-fade': {
      type: Boolean
    },
    'no-enforce-focus': {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    'hide-footer': {
      type: Boolean,
      default: false
    },
    'ok-only': {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false)
      this.$emit('close')
    },
    shown() {
      this.$emit('shown')
    },
    change(isVisible) {
      this.$emit('change', isVisible)
    },
    hide() {
      this.$emit('update:show', false)
      this.$emit('hide')
    },
    ok(event) {
      this.$emit('ok', event)
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.$refs['app-modal'].show()
      } else {
        this.$refs['app-modal'].hide()
      }
    }
  }
}
</script>
<style>
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.6) !important;
}
</style>
